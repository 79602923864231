<template>
  <nav class="bg-white shadow">
    <div class="max-w-7xl mx-auto px-4">
      <div class="flex justify-between h-16">
        <!-- Logo -->
        <div class="flex-shrink-0 flex items-center">
          <span class="text-2xl font-bold">Logo</span>
        </div>

        <!-- Main Navigation -->
        <div class="hidden md:flex">
          <div class="relative group">
            <button
              class="inline-flex items-center px-4 py-2 text-gray-700 hover:text-gray-900 focus:outline-none group"
              @mouseenter="productOpen = true"
              @mouseleave="productOpen = false"
            >
              Products
              <svg
                class="ml-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>

            <!-- Mega Menu Dropdown -->
            <div
              v-show="productOpen"
              @mouseenter="productOpen = true"
              @mouseleave="productOpen = false"
              class="absolute left-0 w-screen max-w-screen-xl mx-auto px-8 transform -translate-x-1/4 mt-0 bg-white border-t border-gray-100 shadow-lg"
            >
              <div class="grid grid-cols-4 gap-8 py-8">
                <!-- Category 1 -->
                <div>
                  <h3 class="text-lg font-medium text-gray-900 mb-4">
                    Category 1
                  </h3>
                  <ul class="space-y-4">
                    <li v-for="(item, index) in category1Items" :key="index">
                      <a
                        :href="item.href"
                        class="text-gray-600 hover:text-gray-900 block"
                      >
                        {{ item.name }}
                      </a>
                    </li>
                  </ul>
                </div>

                <!-- Category 2 -->
                <div>
                  <h3 class="text-lg font-medium text-gray-900 mb-4">
                    Category 2
                  </h3>
                  <ul class="space-y-4">
                    <li v-for="(item, index) in category2Items" :key="index">
                      <a
                        :href="item.href"
                        class="text-gray-600 hover:text-gray-900 block"
                      >
                        {{ item.name }}
                      </a>
                    </li>
                  </ul>
                </div>

                <!-- Category 3 -->
                <div>
                  <h3 class="text-lg font-medium text-gray-900 mb-4">
                    Category 3
                  </h3>
                  <ul class="space-y-4">
                    <li v-for="(item, index) in category3Items" :key="index">
                      <a
                        :href="item.href"
                        class="text-gray-600 hover:text-gray-900 block"
                      >
                        {{ item.name }}
                      </a>
                    </li>
                  </ul>
                </div>

                <!-- Featured Section -->
                <div class="bg-gray-50 p-6 rounded-lg">
                  <h3 class="text-lg font-medium text-gray-900 mb-4">
                    Featured
                  </h3>
                  <div class="space-y-4">
                    <a href="#" class="block">
                      <div class="aspect-w-16 aspect-h-9">
                        <div class="w-full h-32 bg-gray-300 rounded-lg"></div>
                      </div>
                      <p class="mt-3 text-sm text-gray-600">
                        Featured product or promotion description
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Other navigation items -->
          <a href="#" class="px-4 py-2 text-gray-700 hover:text-gray-900"
            >Solutions</a
          >
          <a href="#" class="px-4 py-2 text-gray-700 hover:text-gray-900"
            >Resources</a
          >
          <a href="#" class="px-4 py-2 text-gray-700 hover:text-gray-900"
            >Contact</a
          >
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
let productOpen = ref(false)
let category1Items = [
  { name: 'Product 1.1', href: '#' },
  { name: 'Product 1.2', href: '#' },
  { name: 'Product 1.3', href: '#' },
  { name: 'Product 1.4', href: '#' },
]
let category2Items = [
  { name: 'Product 2.1', href: '#' },
  { name: 'Product 2.2', href: '#' },
  { name: 'Product 2.3', href: '#' },
  { name: 'Product 2.4', href: '#' },
]
let category3Items = [
  { name: 'Product 3.1', href: '#' },
  { name: 'Product 3.2', href: '#' },
  { name: 'Product 3.3', href: '#' },
  { name: 'Product 3.4', href: '#' },
]
</script>
